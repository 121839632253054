export const AUTHENTICATION_ERROR_CODE = 'FIDANT_AUTHENTICATION_ERROR';
export const isAuthenticationError = (error) => error instanceof AuthenticationError;
export const hasAuthenticationError = (error) => hasAuthenticationError_(error);
const hasAuthenticationError_ = (error, visited) => {
    if (!(error instanceof Error) || visited?.has(error)) {
        return false;
    }
    if (error instanceof AuthenticationError) {
        return true;
    }
    visited ??= new Set();
    visited.add(error);
    if (error instanceof AggregateError && error.errors.some(e => hasAuthenticationError_(e, visited))) {
        return true;
    }
    if (error.cause instanceof Error) {
        return hasAuthenticationError_(error.cause, visited);
    }
    return false;
};
export class AuthenticationError extends Error {
    name = 'AuthenticationError';
    // [next@14.0.3] apparently error digests are not preserved for errors thrown by promises in `use()`.
    // They're supposed to be, since they are when thrown directly from a server component.
    // (See: next.git/test/e2e/app-dir/app/app/error/server-component/custom-digest)
    digest = AUTHENTICATION_ERROR_CODE;
    static digest = AUTHENTICATION_ERROR_CODE;
    constructor(message = 'Authentication required', options) {
        super(message, options);
        Error.captureStackTrace(this, new.target);
    }
    static [Symbol.hasInstance](error) {
        return error instanceof Error && 'digest' in error && error.digest === this.digest;
    }
}
